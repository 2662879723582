.banner-item{
    position:relative;
}

.banner-item .banner-item-content{
    position: absolute;
    max-width: 480px;
    top: calc((100% - 132px) / 2);
    right: 200px;
    color: #fff;
}

@media(max-width:991px){
    .banner-item .banner-item-content{
        right: 70px;
    }
}

@media(max-width:767px){
    .banner-item .banner-item-content{
        width:100%;
        top:auto;
        right:auto;
        bottom:0;
        padding:24px;
        max-width: none;
        text-align: center;
    }
}