.pointers-cart-content .card{
    border-radius: 0;
    position:relative;
    
}

.pointers-cart-content .card.card-order-item{
    border-color: transparent;
    border-bottom: 1px solid #ccc;
}

.pointers-cart-content .card .col-img{
    overflow: hidden;
    border:1px solid #ccc;
}

.pointers-cart-content .card img{
    /* width:200%;
    margin-left:-50%; */
    width:100%;
}


.pointers-cart-content .card .text-price{
    text-align: right;
    position: relative;
}

.pointers-cart-content .card .txt-qty{
    float:right;
    padding: 0.375rem 0.75rem;
}


.pointers-cart-content .card .icon-delete{
    cursor:pointer;
}

.pointers-cart-content .card-amount .col-total-amount{
    text-align:right;
}
.pointers-cart-content .card-amount .col-total-amount h3{
    margin:0;
}

.pointers-cart-content .form-control{
    padding: 0.375rem 0.75rem;
}


.pointers-cart-content .opt-shape,
.pointers-cart-content .opt-carat,
.pointers-cart-content .opt-fontstyle {
    width: 20%;
    float: left;
    cursor: pointer;
    margin-bottom: 10px;
    padding-right: 5px;
    border: 1px solid transparent;
}

.pointers-cart-content .opt-fontstyle {
    border: 1px solid #ccc;
    width: calc(25% - 5px);
    padding: 5px;
    margin-right: 5px;
}

.pointers-cart-content .opt-fontstyle.active {
    border: 2px solid #c6054c;
}

.pointers-cart-content .opt-fontstyle img {
    width: 100%;
}
.pointers-cart-content .opt-fontstyle .text-center {
    font-size: 9px;
}
