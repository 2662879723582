.MuiAccordionSummary-root.Mui-expanded{
    background:#efede7;
    /* color:#fff; */
}
.accordion-orderhistory{
    box-shadow: none !important;
    border:1px solid #ccc;
    border-radius: 0 !important;

}

