.wizard-confirmation-content .variants {
  /* display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; */
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.wizard-confirmation-content .variants > span {
  padding-right: 15px;
}

.wizard-confirmation-content .variants .form-label {
  font-weight: bold;
}

.wizard-confirmation-content .img-card {
  border: none !important;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
  border-radius: 0;
}

.wizard-confirmation-content .img-card img {
  /* width: 200%;
    margin-left: -50%;
    height: auto; */
  width: 100%;
  height: auto;
}

.wizard-confirmation-content .img-card img.img-fluid {
  margin-left: 0;
}

.wizard-confirmation-content .img-card .label-360 {
  position: absolute;
  bottom: 10px;
  text-align: center;
  width: 100%;
}

.wizard-confirmation-content .text-price {
  color: #c6054c;
  font-size: 32px;
  font-weight: bold;
}

.wizard-confirmation-content .text-setting-only {
  font-size: 16px;
  font-weight: normal;
  color: #333;
}

.wizard-confirmation-content .heading {
  font-family: OpenSansFont;
  color: inherit;
  font-size: 20px;
  letter-spacing: 0.025em;
  margin: 0 0 5px 0;
}

.wizard-confirmation-content .opt-shape,
.wizard-confirmation-content .opt-carat {
  width: 20%;
  float: left;
  cursor: pointer;
  margin-bottom: 10px;
  padding-right: 5px;
  border: 1px solid transparent;
}

.wizard-confirmation-content .opt-carat .carat {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}

.wizard-confirmation-content .opt-carat .carat.active {
  border: 1px solid #c6054c;
}

.wizard-confirmation-content .list-metal-circles li {
  width: 40px;
  height: 40px;
  border: 2px solid transparent;
}

.wizard-confirmation-content .list-metal-circles li.active {
  border: 2px solid #c6054c;
}

.wizard-confirmation-content .col-icon {
  width: 40px;
  float: left;
}

.wizard-confirmation-content .col-delivery {
  width: calc(100% - 40px);
  padding-left: 15px;
  float: left;
}

.wizard-confirmation-content .card-made-for-u {
  background-color: #efede7;
  border-color: #efede7;
  /* color: #fff; */
}

.wizard-confirmation-content .card-made-for-u .card-body {
  padding: 50px;
}

@media (max-width: 480px) {
  .wizard-confirmation-content .card-made-for-u .card-body {
    padding: 20px;
  }
}

.wizard-confirmation-content .card-made-for-u .MuiAccordionSummary-root {
  background-color: #efede7 !important;
  /* color: #fff; */
}

.wizard-confirmation-content .card-made-for-u .MuiAccordionSummary-content {
  margin: 15px 0 10px 0;
}

.wizard-confirmation-content .card-made-for-u .MuiPaper-elevation1 {
  box-shadow: none !important;
  border-top: 1px solid #333;
}

/* .wizard-confirmation-content .MuiIconButton-label {
    color: #fff;
} */

.wizard-confirmation-content .card-made-for-u .accordion-made-for-u .col-img {
  width: 50px;
  float: left;
}

.wizard-confirmation-content .card-made-for-u .accordion-made-for-u .col-desc {
  width: calc(100% - 50px);
  float: left;
  padding-left: 5px;
}

.wizard-confirmation-content
  .card-made-for-u
  .accordion-made-for-u
  .col-desc
  h3 {
  font-size: 18px !important;
}

.wizard-confirmation-content
  .card-made-for-u
  .accordion-made-for-u
  .col-desc
  small {
  margin-top: 2px;
  display: block;
  text-transform: none;
  letter-spacing: 0.025em;
  font-size: 14px;
}

.wizard-confirmation-content
  .card-made-for-u
  .accordion-made-for-u
  .MuiAccordionDetails-root {
  background-color: #efede7;
  /* color: #fff; */
}

.wizard-confirmation-content .selected-items {
  margin-top: 20px;
}

.wizard-confirmation-content .selected-items .wrapper {
  margin-bottom: 10px;
}

.wizard-confirmation-content .selected-items .col-icon {
  width: 70px;
  text-align: center;
  overflow: hidden;
  border: 1px solid #ccc;
  float: left;
}

.wizard-confirmation-content .selected-items .wrapper > div {
  display: table-cell;
  vertical-align: middle;
  height: 80px;
}

.wizard-confirmation-content .selected-items .col-icon img {
  width: 100%;
}

.wizard-confirmation-content .selected-items .col-icon .img-info {
  /* width: 200%;
    margin-left: -50%; */
  width: 100%;
}

.wizard-confirmation-content .selected-items .col-item {
  width: calc(100% - 70px);
  padding-left: 20px;
}

.wizard-confirmation-content .slick-dots {
  margin-bottom: 0;
  bottom: 10px;
}
.wizard-confirmation-content .slick-dots li {
  margin: 0 10px;
}

.wizard-confirmation-content .slick-bullet {
  border: 1px solid #ccc;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

.wizard-confirmation-content .slick-active .slick-bullet {
  background-color: #ccc;
}
