@media(max-width:991px) {
    .wizard-step-container {
        max-width: 100%;
        padding: 0;
    }
}

.wizard-steps {
    font-family: OpenSansFont;
    color: #333;
    letter-spacing: .8px;
    padding: 0;
    position: relative;
    clear: both;
    display: table;
    width: 100%;
    height: 100px;
    border: 1px solid #ccc;
    border-collapse: separate;
    table-layout: fixed;
    line-height: 1.3;
    position: relative;
    background-color: #ccc;
    margin-bottom: 30px;
}



.wizard-steps .step {
    position: relative;
    width: 33.3%;
    display: table-cell;
    vertical-align: top;
}

.wizard-steps .node {
    position: relative;
    display: block;
    width: auto;
    height: 100px;
    margin-right: 16px;
    background: #fff;
    text-decoration: none;
}

.wizard-steps .node>div {
    display: table-cell;
    vertical-align: middle;
}

.wizard-steps .node:before {
    width: 0;
    height: 0;
    border-top: 50px solid #fff;
    border-bottom: 50px solid #fff;
    border-left: 15px solid transparent;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    transform: translate(-100%, 0);
}

.wizard-steps .step:first-child .node:before {
    border-left: 1px solid #ccc;
    border-top: none;
    border-bottom: none;
}

.wizard-steps .step:last-child .node {
    margin-right: 0;
}


.wizard-steps .node:after {
    width: 0;
    height: 0;
    border-top: 50px solid #ccc;
    border-bottom: 50px solid #ccc;
    border-left: 15px solid #fff;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    transform: translate(100%, 0);
}

.wizard-steps .step:last-child {
    margin-right: 0;
}

.wizard-steps .step:last-child .node:after {
    display: none;
}

.wizard-steps .num {
    font-size: 36px;
    text-align: center;
    width: 70px;
    line-height: 100px;
}

@media(max-width:1023px) {
    .wizard-steps .num {
        width: 23px;
    }
}

.wizard-steps .stepinfo {
    padding-left: 10px;
}


.nostyle-heading {
    color: inherit;
    display: inline-block;
    font-family: OpenSansFont;
    font-size: inherit;
    margin: 0;
    padding: 0;
    /* text-transform: inherit; */
    text-transform: uppercase;
}

@media(max-width:480px) {
    .wizard-steps {
        height: 64px;
    }

    .wizard-steps .node{
        height:64px;
    }
    .wizard-steps .node:before,.wizard-steps .node:after{
        border-top-width: 32px;
        border-bottom-width: 32px;
    }

    .wizard-steps .num{
        line-height: 64px;
    }

    .wizard-steps .stepinfo {
        padding-left: 0;
    }

    .nostyle-heading {
        font-size: 12px;
    }
}

.wizard-steps .data,
.wizard-steps .action {
    font-family: OpenSansFont;
    font-size: 12px;
    letter-spacing: .075em;
    text-transform: capitalize;
}

@media(max-width:991px) {
    .wizard-steps .data {
        display: none;
    }
}

.wizard-steps .action {
    text-decoration: underline;
    color: #555555;
    cursor: pointer;
}

.wizard-steps .stepimg {
    width: 70px;
    text-align: center;
    overflow: hidden;
}

@media(max-width:767px) {
    .wizard-steps .stepimg {
        display: none !important;
    }
}

.wizard-steps .stepimg img {
    width: 100%;
}

/* .wizard-steps .stepimg img.img-info {
    width: 200%;
    margin-left: -50%;
} */


/* .wizard-steps .step.active .node {
    background: #efede7;
} */

/* .wizard-steps .step.active .node:before {
    border-top: 50px solid #efede7;
    border-bottom: 50px solid #efede7;
}

.wizard-steps .step.active .node:after {
    border-left: 15px solid #efede7;
} */