.product-content .variants {
  /* display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; */
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-content .variants > span {
  padding-right: 15px;
}

.product-content .variants .form-label {
  font-weight: bold;
}

.product-content .img-card {
  border: none !important;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
  border-radius: 0;
}

.product-content .img-card img {
  /* width: 200%;
    margin-left: -50%;
    height: auto; */
  width: 100%;
  height: auto;
}

.product-content .img-card .label-360 {
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;
}

.product-content .text-price {
  color: #c6054c;
  font-size: 1.5rem;
  font-weight: bold;
}

.product-content .text-setting-only {
  font-size: 16px;
  font-weight: normal;
  color: #333;
}

.product-content .heading {
  font-family: OpenSansFont;
  color: inherit;
  font-size: 20px;
  letter-spacing: 0.025em;
  margin: 0 0 5px 0;
}

.product-content .opt-shape,
.product-content .opt-carat,
.product-content .opt-fontstyle {
  width: 20%;
  float: left;
  cursor: pointer;
  margin-bottom: 10px;
  padding-right: 5px;
  border: 1px solid transparent;
}

.product-content .opt-fontstyle {
  border: 1px solid #ccc;
  width: calc(25% - 10px);
  padding: 10px;
  margin-right: 10px;
  background-color: #fff;
}

.product-content .opt-fontstyle.active {
  border: 2px solid #c6054c;
}

.product-content .opt-fontstyle img {
  width: 100%;
}

@media (max-width: 480px) {
  .product-content .opt-fontstyle .text-center {
    font-size: 12px;
  }
}

.product-content .opt-carat .carat {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}

.product-content .opt-carat .carat.active {
  border: 1px solid #c6054c;
}

.product-content .list-metal-circles li {
  width: 40px;
  height: 40px;
  border: 2px solid transparent;
  text-align: center;
}

.product-content .list-metal-circles li.active {
  border: 2px solid #c6054c;
}

.product-content .col-icon {
  width: 40px;
  float: left;
}

.product-content .col-delivery {
  width: calc(100% - 40px);
  padding-left: 15px;
  float: left;
}

.product-content .card-made-for-u {
  background-color: #efede7;
  border-color: #efede7;
  /* color: #fff; */
}

.product-content .card-made-for-u .card-body {
  padding: 50px;
}

@media (max-width: 480px) {
  .product-content .card-made-for-u .card-body {
    padding: 20px;
  }
}

.product-content .card-made-for-u .MuiAccordionSummary-root {
  background-color: #efede7 !important;
  /* color: #fff; */
}

.product-content .card-made-for-u .MuiAccordionSummary-content {
  margin: 15px 0 10px 0;
}

.product-content .card-made-for-u .MuiPaper-elevation1 {
  box-shadow: none !important;
  border-top: 1px solid #333;
}

/* .product-content .MuiIconButton-label {
    color: #fff;
} */

.product-content .card-made-for-u .accordion-made-for-u .col-img {
  width: 50px;
  float: left;
}

.product-content .card-made-for-u .accordion-made-for-u .col-desc {
  width: calc(100% - 50px);
  float: left;
  padding-left: 5px;
}

.product-content .card-made-for-u .accordion-made-for-u .col-desc h3 {
  font-size: 18px !important;
}

.product-content .card-made-for-u .accordion-made-for-u .col-desc small {
  margin-top: 2px;
  display: block;
  text-transform: none;
  letter-spacing: 0.025em;
  font-size: 14px;
}

.product-content
  .card-made-for-u
  .accordion-made-for-u
  .MuiAccordionDetails-root {
  background-color: #efede7;
  /* color: #fff; */
}

.product-content .slick-prev.arrow {
  margin-left: -10px;
}

.product-content .slick-next.arrow {
  margin-right: -10px;
}

.product-content .slick-dots {
  margin-bottom: 0;
  bottom: 10px;
}
.product-content .slick-dots li {
  margin: 0 10px;
}

.product-content .slick-bullet {
  border: 1px solid #ccc;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

.product-content .slick-active .slick-bullet {
  background-color: #ccc;
}

.modal-drophint .modal-content {
  border-radius: 0;
}

.modal-drophint .modal-body {
  padding-top: 40px;
}

.modal-drophint .col-img {
  width: 70px;
  float: left;
}

.modal-drophint .col-prod-desc {
  width: calc(100% - 70px);
  float: left;
}

.modal-drophint .img-card img {
  width: 100%;
  height: auto;
}

.modal-drophint .icon-close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.modal-drophint .form-drophint {
  border: 1px solid #ccc;
  padding: 15px;
}

.modal-drophint .text-privacy {
  font-size: 10px;
}

.btn-360 {
  width: 100% !important;
}

.btn-360 svg {
  margin-top: -5px;
}

.product-content .MuiSlider-root {
  color: #c6054c;
}

.product-content .variant .slick-current {
  border-bottom: 5px solid #c6054c;
}

@media (max-width: 767px) {
  .product-content .opt-shape,
  .product-content .opt-carat {
    padding-right: 0;
  }
}

.product-content .card-contacts {
  background-color: #efede7;
}

.product-content .card-contacts .icon-drophint {
  position: relative;
}

.product-content .card-contacts .drophint-love {
  position: absolute;
  top: -10%;
  left: 42%;
  font-size: 1rem;
}

@media (max-width: 767px) {
  .product-content .card-contacts .drophint-love {
    left: 40%;
  }
}

.product-content .bg-needhelp-1 {
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 15px;
}

.product-content .bg-needhelp-2 {
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
  margin-bottom: 15px;
}

.MuiSlider-markLabel {
  display: none;
}

.MuiSlider-marked {
  margin-bottom: 0px !important;
}

.product-content .m-product-desc {
  display: none;
}

.product-content .m-product-desc .desc-see-more,
.product-content .m-product-desc .desc-see-less {
  cursor: pointer;
  text-decoration: underline;
}

.product-content .m-product-desc .desc-see-less {
  display: none;
}

.product-content .m-product-desc.active .desc-see-more {
  display: none;
}
.product-content .m-product-desc.active .desc-see-less {
  display: block;
}

.product-content .m-product-desc .product-desc {
  max-height: 100px;
  overflow: hidden;
  margin-bottom: 10px;
}

.product-content .m-product-desc.active .product-desc {
  max-height: 100%;
  overflow: hidden;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .product-content .d-product-desc {
    display: none;
  }

  .product-content .m-product-desc {
    display: block;
  }
}

.product-content .classification-badge {
  left: 15px;
}
