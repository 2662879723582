.imageFingerSliderBox .productSliderImages {
  position: relative;
}

.imageFingerSliderBox .productSliderImages img {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.imageFingerSliderBox .productSliderImages .img-hand-dark,
.imageFingerSliderBox .productSliderImages .img-hand-black,
.imageFingerSliderBox .productSliderImages .img-ring-overlay {
  position: absolute;
  left: 0;
  top: 0;
}

.imageFingerSliderBox .divSlider {
  z-index: 5;
  position: absolute;
  width: 100%;
  bottom: 24px;
  background-color: #fff;
}

.imageFingerSliderBox .divSlider .sliderLabel {
  float: left;
  width: 60px;
}
.imageFingerSliderBox .divSlider .sliderLabel:last-child {
  text-align: right;
}

.imageFingerSliderBox .divSlider .slider {
  width: calc(100% - 120px);
  float: left;
}

.imageFingerSliderBox .MuiSlider-root {
  height: auto;
}

@media (max-width: 767px) {
  .imageFingerSliderBox .divSlider .sliderLabel {
    padding-top: 8px;
  }
}
