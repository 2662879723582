footer {
    background-color: #efede7;
}

.footer-top_area {
    padding: 80px 0 10px 0;
}

.footer-info .footer-icon {
    color: #555555;
}

.footer-info .footer-logo {
    padding-bottom: 25px;
}

.footer-info .footer-logo .img-logo {

    width: 250px;
}

.footer-info .footer-title {
    font-size: 18px;
    color: #555555 !important;
    font-weight: bold;

    font-family: OpenSansFont;
}

footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-top_area ul>li {
    padding-bottom: 10px;
}

.footer-top_area .footer-socials ul>li {
    display: inline-block;
    padding-right: 10px;
}

.footer-bottom_area ul>li {
    display: inline-block;
    padding-right: 30px;
}

.footer-top_area .footer-socials .footer-icon {
    /* font-size: 75px; */
    font-size: 30px;
    color: #fff;
    background-color: #333;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    padding: 8px;

}

/* .footer-top_area .footer-socials .footer-icon.facebook {
    color: #4267B2;
}

.footer-top_area .footer-socials .footer-icon.instagram {
    color: #fb3958;
} */

.footer-bottom_area .footer-bottom_nav {
    border-top: 1px solid #e5e5e5;
    padding: 30px 0 35px;
    text-align: center;
}

.footer-bottom_area .footer-bottom_nav ul>li {
    font-weight: bold;
}

@media(max-width:991px) {
    .footer-sm-mt {
        margin-top: 30px;
    }
}

.footer-info .img-graded{
    max-width: 50px;;
}