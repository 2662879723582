.page-container .banner-ourstory{
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    height:300px;
    position: relative;
}

.banner-ourstory .banner-content{
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.banner-ourstory .banner-content.left {
    left: 10%;
}


.banner-ourstory .banner-content>h5 {
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 0;
    padding-bottom: 12px;
}

.banner-ourstory .banner-content span {
    color: #c6054c;
}

.banner-ourstory .banner-content>h2 {
    text-transform: capitalize;
    margin-bottom: 0;
    padding-bottom: 10px;
    font-size: 40px;
}

.banner-ourstory .banner-content>h3 {
    text-transform: capitalize;
    margin-bottom: 0;
    padding-bottom: 20px;
    font-size: 40px;
}

.banner-ourstory .banner-content>h4{
    margin-bottom: 0;
    padding-bottom: 45px;
    font-size: 20px;
}

.ourstory-content .row-about{
    display: flex;
    align-items: center;
}



.ourstory-content .row-about .col-about{
    flex: 1;
    margin:0 5px;
}


.ourstory-content .row-about .card-about{
    background-color: #c6054c;
    text-align: center;
    padding:50px 0;
}

.ourstory-content .card-about img{
    max-width:100px;
    margin:0 auto;
}

.ourstory-content .card-about .text-about{
    color:#fff;
    margin-top:20px;
    margin-bottom:0;
}

.ourstory-content .row-vision{
    display:flex;
    min-height:540px;
}

.ourstory-content .row-vision.reverse{
    flex-direction: row-reverse;
}


.ourstory-content .row-vision .col-vision{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display:flex;
    flex:1;
    align-items: center;
    background-color: #efede7;
    /* color:#fff; */
}

.ourstory-content .row-vision .col-vision.left{
    float:left;
}

.ourstory-content .row-vision .col-vision.right{
    float:right;
}


/* .ourstory-content .row-vision .col-vision ul{
    list-style-type: disc;
    padding-left: 1.5rem;
} */


.ourstory-content .row-services .col-services{
    padding:20px;
}

.ourstory-content .row-services img{
    max-height:100px;
}

.ourstory-content .row-services .text-services{
    margin-top:20px;
    font-weight: bold;
}


@media(max-width:767px){
    .ourstory-content .row-about{
        display: block;
    }

    .ourstory-content .row-about .col-about{
        margin-bottom: 10px;;
    }

    .ourstory-content .row-vision{
        display:block;
    }

    .ourstory-content .row-vision .col-vision{
        min-height:350px;
    }

}