.orderhistorylist-content .label-order-status {
    position: absolute;
    right: 10px;
    top: 5px;
    text-align: right;
}

.accordion-orderhistory{
    box-shadow: none !important;
    border:1px solid #ccc;
    border-radius: 0 !important;

}


.orderhistorylist-content  .MuiAccordionDetails-root{
    display:block;
}

.orderhistorylist-content .MuiAccordionDetails-root img{
    border: 1px solid #ccc;
}

.orderhistorylist-content .MuiAccordionDetails-root .row-grandtotal{
    text-align: right;
}