.productlist-content .list-metal-circles li.active {
  border: 2px solid #c6054c;
}

.productlist-content .card-product:hover .img-ring-top {
  display: none;
}

.productlist-content .card-product .img-ring-side {
  display: none;
}

.productlist-content .card-product:hover .img-ring-side {
  display: block;
}

.productlist-content .slick-dots {
  bottom: 0;
}
.productlist-content .slick-bullet {
  border: 1px solid #ccc;
  width: 10px;
  height: 10px;
  margin: 0 auto;
  border-radius: 100%;
}

.productlist-content .slick-active .slick-bullet {
  background-color: #ccc;
}

@media (max-width: 480px) {
  .productlist-content .slick-bullet {
    width: 5px;
    height: 5px;
  }
}


