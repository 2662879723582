.pointers-checkout-content .card{
    border-radius: 0;
    border-color:#ccc;
    position:relative;
}

.pointers-checkout-content .card img{
    border:1px solid #ccc;
}

.pointers-checkout-content .card .text-price{
    text-align: right;
    position: relative;
}

.pointers-checkout-content .card .txt-qty{
    float:right;
    padding: 0.375rem 0.75rem;
}


.pointers-checkout-content .card .icon-delete{
    cursor:pointer;
}

.pointers-checkout-content .card-amount{
    float:none;
    width:auto;
}