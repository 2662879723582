.container-contactus .map {
    width: 100% !important;
    height: 400px;
}

.container-contactus .contact-icon {
    color: #555555;
}


.container-contactus .contact-socials ul>li {
    display: inline-block;
    padding-right: 10px;
}

.container-contactus .contact-socials .contact-icon {
    font-size: 75px;
}

.container-contactus .contact-socials .contact-icon.facebook {
    color: #4267B2;
}

.container-contactus .contact-socials .contact-icon.instagram {
    color: #fb3958;
}