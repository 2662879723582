@font-face {
  font-family: OpenSansFont;
  src: url("../public/fontface/OpenSans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: EngraveClassicFont;
  src: url("../public/fontface/Noto_Serif/NotoSerif-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: EngraveModernFont;
  src: url("../public/fontface/Roboto/Roboto-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: EngraveItalicFont;
  src: url("../public/fontface/Noto_Serif/NotoSerif-Italic.ttf");
  font-weight: 400;
}

@font-face {
  font-family: EngraveCursiveFont;
  src: url("../public/fontface/DancingScript/DancingScript-Regular.ttf");
  font-weight: 400;
}

* {
  -webkit-touch-callout: none; /* Prevent callout to copy image, etc when tap to hold */
  -webkit-user-select: none; /* Disable text selection */
  -webkit-tap-highlight-color: rgba(
    0,
    0,
    0,
    0
  ); /* Remove tap highlight color */
  touch-action: manipulation; /* Disable zooming */
}

body {
  overflow-x: hidden;
  max-width: 100%;
  font-family: OpenSansFont;
  background-color: #F0DAD4;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: OpenSansFont;
}

a,
a:hover {
  text-decoration: none;
  color: #212529;
}

button {
  outline: none !important;
  box-shadow: none !important;
}

.form-control {
  border-radius: 0 !important;
}

.form-control:focus {
  outline: 0;
  border-color: #ced4da;
  box-shadow: none;
  /* background-color: transparent; */
}

.container {
  max-width: 1140px;
}

.w-100 {
  width: 100% !important;
}

.position-relative {
  position: relative !important;
}

.border-1px-solid {
  border: 1px solid #ddd !important;
}

.border-hover {
  border: 1px solid transparent;
}

.border-hover:hover {
  border: 1px solid #efede7;
}

.header-bottom_area .main-menu_area > nav > ul > li > a:before,
.header-bottom_area .main-menu_area > nav > ul > li > a:after,
.mobile-menu_wrapper .offcanvas-menu-inner {
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.mobile-menu_wrapper .offcanvas-menu-inner,
.offcanvas-minicart_wrapper .offcanvas-menu-inner,
.offcanvas-search_wrapper .offcanvas-menu-inner {
  position: fixed;
  top: 0;
  right: -285px;
  width: 285px;
  height: 100%;
  background: #ffffff;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
}

.mobile-menu_wrapper.open .offcanvas-menu-inner,
.offcanvas-minicart_wrapper.open .offcanvas-menu-inner,
.offcanvas-search_wrapper.open .offcanvas-menu-inner {
  left: auto;
  right: 0;
  visibility: visible;
  opacity: 1;
  padding: 105px 0 0;
  border: 1px solid #ddd;
}

.mobile-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_search,
.offcanvas-minicart_wrapper .offcanvas-menu-inner .offcanvas-inner_search,
.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-inner_search {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: calc(100% - 0px);
  background-color: #e5e5e5;
}

.offcanvas-menu-inner .btn-close {
  position: absolute;
  top: -1px;
  left: -60px;
  background: #333333;
  width: 60px;
  height: 60px;
  line-height: 55px;
  text-align: center;
  color: #ffffff !important;
  z-index: 10;
  font-size: 24px;
  border-radius: 0;
  padding: 0;
  opacity: 1 !important;
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.text-red {
  color: #c6054c;
}

.page-container {
  width: 100%;
}

.page-container ul {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.form-control,
.form-select {
  border: 1px solid #ccc;
}

.recaptcha {
  float: left;
}

.btn-submit {
  border-radius: 0;
  float: right;
}

.text-right {
  text-align: right;
}

.btn-red {
  border-radius: 0;
  background: #efede7 !important;
  color: #555 !important;
  font-family: OpenSansFont;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #efede7 !important;
}

.btn-white {
  border-radius: 0;
  background: #fff !important;
  color: #212529 !important;
  border: 1px solid #ddd !important;
}

.bg-red {
  background-color: #c6054c !important;
  color: #fff !important;
}

.bg-milk {
  background-color: #efede7 !important;
  color: #333 !important;
}

.btn-100 {
  width: 100% !important;
}

.text-crossline {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline !important;
}

.card {
  border-color: #ccc;
  border-radius: 0;
}

.requiredLabel:after {
  content: "*";
  color: red;
}

.card-addresses {
  float: left;
  width: calc(60% - 20px);
}

.card-amount {
  float: right;
  width: 40%;
}

.card-amount .col-total-amount {
  text-align: right;
}

.card-amount .col-total-amount h3 {
  margin: 0;
}

.card-product {
  border-color: transparent;
  margin-bottom: 25px;
}

.card-product .img-product {
  overflow: hidden;
}

.card-product .img-product.img-diamond {
  position: relative;
}

/* .card-product img {
  width: 200%;
  margin-left: -50%;
  margin-top: -10%;
} */

.card-product img.img-fluid {
  margin-left: auto;
  margin-top: auto;
  border-radius: 0;
}

.card-product .viewmore {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  /* opacity: .7; */
  font-weight: bold;
  background-color: #efede7 !important;
}

.card-product .viewmore .buttonview {
  cursor: pointer;
  border: 1px solid #000;
  width: 40px;
  margin-left: 2px;
  margin-right: 2px;
}

.card-product:hover .viewmore {
  display: block;
}

.card-product:hover {
  border-color: #dfdbcf;
  /* width: calc(100% + 20px);
  margin-top: -10px;
  margin-left: -10px;
  margin-bottom: -10px; */
}

/* .card-product .desc {
  margin-top: -20%;
} */

.top-dotted {
  border-top: 1px dotted #555;
}

.list-metal-circles {
  padding-left: 0;
  list-style: none;
}

.list-metal-circles li {
  display: inline-block;
  cursor: pointer;
  margin: 5px;
  letter-spacing: normal;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  border: 1px solid white;
}

.list-metal-circles .gold {
  background: #e1b94f !important;
  background: -moz-linear-gradient(
    30deg,
    #e1b94f 0,
    #fcf1d5 55%,
    #fcf1d5 60%,
    #e1b94f 100%
  ) !important;
  background: -webkit-linear-gradient(
    30deg,
    #e1b94f 0,
    #fcf1d5 55%,
    #fcf1d5 60%,
    #e1b94f 100%
  ) !important;
  background: linear-gradient(
    30deg,
    #e1b94f 0,
    #fcf1d5 55%,
    #fcf1d5 60%,
    #e1b94f 100%
  ) !important;
}

.list-metal-circles .white {
  background: #b3b2af !important;
  background: -moz-linear-gradient(
    30deg,
    #b3b2af 0,
    #f7f7f7 55%,
    #f7f7f7 60%,
    #b3b2af 100%
  ) !important;
  background: -webkit-linear-gradient(
    30deg,
    #b3b2af 0,
    #f7f7f7 55%,
    #f7f7f7 60%,
    #b3b2af 100%
  ) !important;
  background: linear-gradient(
    30deg,
    #b3b2af 0,
    #f7f7f7 55%,
    #f7f7f7 60%,
    #b3b2af 100%
  ) !important;
}

.list-metal-circles .yellow {
  background: #e1b94f !important;
  background: -moz-linear-gradient(
    30deg,
    #e1b94f 0,
    #fcf1d5 55%,
    #fcf1d5 60%,
    #e1b94f 100%
  ) !important;
  background: -webkit-linear-gradient(
    30deg,
    #e1b94f 0,
    #fcf1d5 55%,
    #fcf1d5 60%,
    #e1b94f 100%
  ) !important;
  background: linear-gradient(
    30deg,
    #e1b94f 0,
    #fcf1d5 55%,
    #fcf1d5 60%,
    #e1b94f 100%
  ) !important;
}

.list-metal-circles .rose {
  background: #ec8a55 !important;
  background: -moz-linear-gradient(
    30deg,
    #ec8a55 0,
    #fcede2 55%,
    #fcede2 60%,
    #ec8a55 100%
  ) !important;
  background: -webkit-linear-gradient(
    30deg,
    #ec8a55 0,
    #fcede2 55%,
    #fcede2 60%,
    #ec8a55 100%
  ) !important;
  background: linear-gradient(
    30deg,
    #ec8a55 0,
    #fcede2 55%,
    #fcede2 60%,
    #ec8a55 100%
  ) !important;
}

.list-metal-circles .silver,
.list-metal-circles .platinum {
  background: #b3b2af !important;
  background: -moz-linear-gradient(
    30deg,
    #b3b2af 0,
    #f7f7f7 55%,
    #f7f7f7 60%,
    #b3b2af 100%
  ) !important;
  background: -webkit-linear-gradient(
    30deg,
    #b3b2af 0,
    #f7f7f7 55%,
    #f7f7f7 60%,
    #b3b2af 100%
  ) !important;
  background: linear-gradient(
    30deg,
    #b3b2af 0,
    #f7f7f7 55%,
    #f7f7f7 60%,
    #b3b2af 100%
  ) !important;
}

.list-metal-circles .undefined {
  display: none !important;
}

.card-product .title1:hover {
  color: #c6054c;
}

.card-product .text-price {
  font-size: 16px;
  color: #c6054c;
}

@media (max-width: 767px) {
  .card-addresses {
    width: 100%;
  }

  .card-amount {
    width: 100%;
  }
}

.card-msg {
  background-color: #efede7;
  border-color: #efede7;
}

.engrave-italic {
  font-family: EngraveItalicFont;
}

.engrave-cursive {
  font-family: EngraveCursiveFont;
}

.engrave-modern {
  font-family: EngraveModernFont;
}

.engrave-classic {
  font-family: EngraveClassicFont;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: grid;
  justify-content: center;
  align-items: center;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

@media (max-width: 480px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

.txt-phone-country {
  width: 70px;
  float: left;
}

.txt-phone {
  width: calc(100% - 70px);
  float: left;
}

@media (max-width: 767px) {
  .text-xs-center {
    text-align: center;
  }
}

.link-reset {
  cursor: pointer;
  text-decoration: underline;
}

.absolute-d-none {
  display: none !important;
}

.icon-info{
  color:#c6054c;
  cursor: pointer;
}

.tooltip-arrow{
  top:50% !important;
}

.classification-badge {
  position: absolute;
  z-index: 5;
  padding: 4px 8px;
  border-radius: 16px;
  background-color: #c6054c;
  color: #fff;
  top: 5px;
  left: 5px;
  font-size: 12px;
}

.position-relative{
  position: relative;
}