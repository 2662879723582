.row-bridals {
  text-align: left;
}

.row-bridals h5 {
  font-weight: bold;
}

@media (max-width: 767px) {
  .row-bridals h5 {
    font-size: 16px;
  }

  .row-bridals {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .row-bridals button {
    width: 100%;
  }
}

.review .img-profile {
  width: 100px;
  border-radius: 100%;
  margin: 0 auto;
}

.review .star-yellow {
    color:#fbbc04;
  }
