.MuiAccordionSummary-root.Mui-expanded{
    background:#efede7;
    /* color:#fff; */
}

.accordion-profile{
    box-shadow: none !important;
    border:1px solid #ccc;
    border-radius: 0 !important;

}

.row-profile{
    width:100%;
}

.btn-red{
    width:auto;
}