.image360box {
    position: relative;
}
.image360box:active {
    cursor: grabbing; /* Change this to the desired cursor style */
  }

.image360box .img-fluid {
    display: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: none;
    cursor: inherit;
    -webkit-user-drag: none;
}

.image360box .img-fluid.active {
    display: block;
}

