@keyframes filterexpand {
  to {
    display:block;
    
  }
}


.productlist-content .shop-by-categories .active{
  border:1px solid #c6054c;
  font-weight: bold;
  color: #c6054c;
}

.productlist-content .shop-by-categories .subcat-img img {
  margin: 0 auto;
}

/* .productlist-content .shop-by-categories .subcat-img{
    border-radius: 100%;
    background-color: #efede7;
} */

.modal-filters .icon-close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.modal-filters .modal-content {
  border-radius: 0;
}

.modal-filters .form-drophint {
  border: 1px solid #ccc;
  padding: 15px;
}


.modal-filters .MuiSlider-root{
  color:#c6054c;
}
.modal-filters .txtPriceRange{
  width:100px;
}
.modal-filters .txtPriceRange.left{
  float:left;
}
.modal-filters .txtPriceRange.right{
  float:right;
}
.modal-filters .btn-shape{
  width:20%;
  float:left;
  text-align: center;
}

.modal-filters .btn-shape.active{
  border: 1px solid #c6054c;
  color:#c6054c;
  font-weight: bold;
}

@media(max-width:767px){
  .modal-filters .label-shape{
    display:none;
  }
}

.section-filter{
  position: relative;
}

.section-filter .btn-filter{
  border-radius: 32px;
}

.tooltip-filter{
  /* position:absolute; */
  /* top:100%; */
  /* z-index: 99; */
  /* background: #fff; */
  /* width:750px; */
  /* left:calc((100% - 750px) / 2); */
  /* border: 1px solid #ddd; */
  display:none;
}

.tooltip-filter.active{
  display:block;
}

.tooltip-filter .icon-close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.tooltip-filter .btn-shape{
  border: 1px solid transparent;
  width:10%;
  float:left;
  text-align: center;
  padding:0;
  cursor:pointer;
}

.tooltip-filter .btn-shape.active{
  border: 1px solid #c6054c;
  color:#c6054c;
  font-weight: bold;
}

.tooltip-filter .filter-price{
  max-width:450px;
  margin:0 auto;
}

.tooltip-filter  .MuiSlider-root{
  color:#c6054c;
}
.tooltip-filter  .txtPriceRange{
  width:100px;
}
.tooltip-filter  .txtPriceRange.left{
  float:left;
}
.tooltip-filter  .txtPriceRange.right{
  float:right;
}

.m-filters{
  position:fixed;
  bottom:0;
  width:100%;
}

@media(max-width:767px){
  .m-filters .btn-filter{
    border-radius:32px 32px 0 0;
  }
  .tooltip-filter{
    position: relative;
    left:0;
    top:0;
    width:100%;
    z-index:2147483647;
    background-color: #fff;
    border:1px solid #ddd;
  }
  .tooltip-filter .btn-shape{
    width:50%;
    text-align: left;
  }
  .tooltip-filter .btn-shape img{
    width:50px;
    float:left;
  }
  .tooltip-filter .btn-shape .label-shape{
    float:left;
    padding-top:10px;
  }

}