.choose-diamond-content {

}

.choose-diamond-content .section{
    margin-bottom:15px;
}

.choose-diamond-content .btn-shape{
    cursor:pointer;
    display: inline-block;
    width: 66px;
    border: 1px solid;
    margin-right: 8px;
    text-align: center;
    font-size: 12px;
    float: left;
    margin-bottom: 10px;
    padding: 5px;
    border-color: rgba(102, 102, 102, 0.25);
    background-color: #fff;
    border-radius: 3px;
}

.choose-diamond-content .btn-shape.active{
    border: 1px solid #c6054c;
    color:#c6054c;
    font-weight: bold;
}

.choose-diamond-content .MuiSlider-root{
    color:#c6054c;
}

.choose-diamond-content .txtCaratRange{
    width:100px;
}
.choose-diamond-content .txtPriceRange{
    width:140px;
}
.choose-diamond-content .txtCaratRange.left,.choose-diamond-content .txtPriceRange.left{
    float:left;
}
.choose-diamond-content .txtCaratRange.right,.choose-diamond-content .txtPriceRange.right{
    float:right;
}

.choose-diamond-content .btn-search{
    background-color: #c6054c;
    border-color: #c6054c;
    box-shadow: none;
    margin-bottom:15px;
}

.table-diamonds .img-shape{
    max-width:40px;
    margin:10px 0;
}

.table-diamonds .rdt_TableHeadRow{
    background: #efede7;
    font-weight: bold;
    text-transform: uppercase;
}

.modal-diamond-detail{
    padding-left:0 !important;
}

.modal-diamond-detail .modal-content{
    border-radius: 0;
}

.modal-diamond-detail .modal-body{
    padding:20px;
}

.modal-diamond-detail .icon-close{
    position:absolute;
    right:10px;
    top:10px;
    cursor: pointer;
}

.modal-diamond-detail .img-diamond{
    border:1px solid #ccc;
}


.modal-diamond-detail .col-title{
    width: 40px;
    font-weight: bold;
}

.choose-diamond-content .text-xs-center .btn-shape{
    width:120px;
    float:none;
}

.choose-diamond-content .text-diamond-type,.choose-diamond-content .text-shape, .choose-diamond-content .text-ideal-cut{
    text-align:center;
}

.choose-diamond-content .text-diamond-type .btn-shape{
    width:120px;
    float:none;
}

.choose-diamond-content .text-shape .btn-shape,.choose-diamond-content .text-ideal-cut .btn-shape{
    float:none;
}



.modal-diamond-detail iframe{
    width: 100%;
    height: 100%;
    min-height:500px;
}


.choose-diamond-content .text-diamond-type .h1desc{
    max-width:700px;
    margin:0 auto 1rem auto;
}
