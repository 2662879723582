@keyframes sideMenuAnimationOpenLeft {
    from {
        left: -320px;
    }

    to {
        left: 0;
    }
}

@keyframes sideMenuAnimationOpenRight {
    from {
        right: -320px;
    }

    to {
        right: 0;
    }
}

@keyframes sideMenuAnimationCloseLeft {
    from {
        left: 0;
    }

    to {
        left: -320px;
    }
}

@keyframes sideMenuAnimationCloseRight {
    from {
        right: 0;
    }

    to {
        right: -320px;
    }
}

.header-top_area {
    padding: 10px 0 0 0;
}


.header-logo .img-logo {
    width: 200px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .header-logo .img-logo {
        padding: 5px 0 0;
    }
}

@media (max-width: 767px) {
    .header-logo .img-logo {
        width: 100%;
        max-width: 150px;
    }

    .header-bottom_area .header-logo {
        padding: 10px 0;
    }
}

.header-form-area {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}


.header-searchbox {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    border-radius: 5px;
    width: 100%;
    height: 45px;
}

.header-searchbox input {
    font-size: 13px;
    height: 45px;
    background: transparent;
    width: 100%;
    padding: 0 60px 0 33px;
}

.header-searchbox .btn-header-search {
    border: none;
    background: #efede7;
    color: #555;
    font-size: 18px;
    width: 45px;
    border-radius: 0 2px 2px 0;
    height: 45px;
    line-height: 45px;
    position: absolute;
    right: -1px;
    top: -1px;
    cursor: pointer;
}

.header-searchbox .icon-header-search {
    margin-bottom: 12px;
}

.header-bottom_area {
    /* background: #efede7; */
    position: relative;
    border-bottom: 1px solid #ddd;
}



/* .header-bottom_area .main-menu_area {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
} */

.header-bottom_area ul,
.offcanvas-navigation ul {
    margin: 0;
    padding: 0;
    list-style: none;
}


.header-bottom_area .main-menu_area>nav>ul>li {
    font-weight: 500;
    /* color: #ffffff !important; */
    /* display: block; */
    display: inline-block;
    padding: 18px 0 18px 18px;
    text-transform: uppercase;
    /* position: relative; */
    text-decoration: none;
    cursor: pointer;
    /* letter-spacing: .05em; */
}

.header-bottom_area .main-menu_area>nav>ul>li>.cat-mega-menu {
    position: absolute;
    /* top:100%; */
    max-width: 200px;
    text-align: left;
}

.header-bottom_area .main-menu_area>nav>ul>li.active>.cat-mega-menu {
    display: block;
    top: 100%;
    max-width: 200px;
    /* left: 0; */
}
.header-bottom_area .main-menu_area>nav>ul>li.is-engagement-ring-cat.active>.cat-mega-menu {
    
    max-width: 600px;
    /* left: 0; */
}

/* .header-bottom_area .main-menu_area>nav>ul>li>a:before {
    content: "";
    border-left-width: 2px;
    border-left-style: solid;
    border-left-color: inherit;
    position: absolute;
    top: 50%;
    left: -20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 13px;
    width: 2px;
    opacity: 0;
    visibility: hidden;
}

.header-bottom_area .main-menu_area>nav>ul>li:hover>a:before {
    opacity: 1;
    visibility: visible;
    left: -10px;
}

.header-bottom_area .main-menu_area>nav>ul>li>a:after {
    content: "";
    border-left-width: 2px;
    border-left-style: solid;
    border-left-color: inherit;
    position: absolute;
    top: 50%;
    right: -20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 13px;
    width: 2px;
    opacity: 0;
    visibility: hidden;
}

.header-bottom_area .main-menu_area>nav>ul>li:hover>a:after {
    opacity: 1;
    visibility: visible;
    right: -10px;
} */

.header-top-right_area {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

@media (max-width: 767px) {
    .header-top-right_area {
        -webkit-box-pack: right;
        -webkit-justify-content: right;
        -ms-flex-pack: right;
        justify-content: right;
        padding-right: 10px;
    }

    .header-top-left_area {
        -webkit-box-pack: left;
        -webkit-justify-content: left;
        -ms-flex-pack: left;
        justify-content: left;
        padding-left: 10px;
    }
}

ul.ul-inline>li {
    display: inline-block;
}

.header-top-left_area>ul>li>a,
.header-top-left_area>ul>li>div {
    padding-right: 15px;

}

.header-top-right_area>ul>li>a,
.header-top-right_area>ul>li>div {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    display: block;
    /* color: #ffffff; */
    font-size: 20px;
    cursor: pointer;
}

@media(max-width:767px) {

    .header-top-right_area>ul>li>a,
    .header-top-right_area>ul>li>div {
        width: 30px;
        height: 30px;
        line-height: 60px;
    }
}

.offcanvas-navigation .mobile-menu>li {
    height: 100%;
    border-bottom: 1px solid #e5e5e5;
}

.offcanvas-navigation .mobile-menu li {
    position: relative;
}

.offcanvas-navigation .mobile-menu li a {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    display: block;
    padding: 10px 0;
    color: #212529;
    text-decoration: none;
}

.category-heading,
.profile-heading {
    background: #efede7;
    position: relative;
    cursor: pointer;
    padding: 19px 0;
}

.blocking {
    position: fixed;
    height: 100%;
    width: 100%;
    /* top:160px; */
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #000;
    opacity: .4;
    display: none;
}

.blocking.active {
    display: block;
}

.category-menu {
    position: fixed;
    height: 100%;
    width: 320px;
    /* top:160px; */
    top: 0;
    left: -320px;
    z-index: 9999;
    background-color: #fff;
    overflow: auto;
    height:100%;
}

.profile-menu {
    position: fixed;
    height: 100%;
    width: 320px;
    /* top:160px; */
    top: 0;
    right: -320px;
    z-index: 9999;
    background-color: #fff;
}

.category-menu.active {
    animation-name: sideMenuAnimationOpenLeft;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

.profile-menu.active {
    animation-name: sideMenuAnimationOpenRight;
    animation-duration: .5s;
    animation-fill-mode: forwards;

}

.category-menu.inactive {

    animation-name: sideMenuAnimationCloseLeft;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

.profile-menu.inactive {
    animation-name: sideMenuAnimationCloseRight;
    animation-duration: .5s;
    animation-fill-mode: forwards;

}

.category-menu .category-heading>h2,
.profile-menu .profile-heading>h2 {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-size: 16px;
}

.category-menu .category-heading>h2>span,
.profile-menu .profile-heading>h2>span {
    padding-left: 50px;
    font-weight: 600;
}


.category-menu .category-heading>h2 .icon,
.profile-menu .profile-heading>h2 .icon {
    font-weight: 600;
    position: absolute;
    top: 18px;
    left: 20px;
    transform: rotate(180deg);
}

.category-menu .category-menu-list,
.profile-menu .profile-menu-list {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    padding: 0 20px;
    /* position: absolute; */
    /* left: 0; */
    /* top: calc(100% - 1px); */
    display: block;
    width: 100%;
    z-index: 3;
}

.category-menu .category-menu-list>ul>li,
.profile-menu .profile-menu-list>ul>li {
    position: relative;
}

.category-menu .category-menu-list>ul>li>.main-category,
.profile-menu .profile-menu-list>ul>li>.main-profile {
    display: block;
    text-transform: capitalize;
    overflow: hidden;
    font-weight: 400;
    padding: 11.2px 0;
    cursor: pointer;
}

.category-menu .category-menu-list>ul>li.right-menu>.main-category .icon,
.profile-menu .profile-menu-list>ul>li.right-menu>.main-profile .icon {
    font-weight: 600;
    position: absolute;
    top: 10px;
    right: 0;
}

.cat-mega-menu,
.profile-mega-menu {
    /* position: absolute; */
    top: 0;
    /* left: 108%; */
    background: #efede7;
    z-index: 999;
    width: 100%;
    /* box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%); */
    /* opacity: 0; */
    /* visibility: hidden; */
    padding: 5px 0;
    /* overflow: hidden; */
    /* border-top: 2px solid #c6054c;
    border-bottom: 2px solid #c6054c; */
    display: none;
}

@media(max-width:767px) {

    .cat-mega-menu,
    .profile-mega-menu {
        background: transparent;
    }
}

.category-menu .category-menu-list>ul>li.active .cat-mega-menu,
.profile-menu .profile-menu-list>ul>li.active .profile-mega-menu {
    display: block;
}


.cat-mega-menu>li.cat-mega-title>a,
.profile-mega-menu>li.profile-mega-title>a {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 15px;
    display: block;
    line-height: 20px;
    position: relative;
}

.cat-mega-menu>li ul>li>a,
.profile-mega-menu>li ul>li>a {
    font-size: 13px;
    display: block;
    line-height: 25px;
    font-weight: 400;
    color: #666;
    text-transform: capitalize;
    padding: 5px 15px;
}

.cat-mega-menu>li .col-thumbnail{
    width:30px;
    float:left;
    border-radius: 100%;
    overflow: hidden;
    text-align: center;
}
.cat-mega-menu>li .col-label{
    width:calc(100% - 30px);
    float:left;
    font-size: 14px;
    padding: 5px 5px;
    font-size: 13px;
    display: block;
    line-height: 25px;
    font-weight: 400;
    color: #666;
    text-transform: capitalize;
}

.cart-relative{
    position:relative;
}

.cart-reddot{
    position: absolute;
    right:25%;
    bottom:25%;
    width:10px;
    height:10px;
    border-radius: 100%;
    background-color: red;
    display:none;
}

.cart-reddot.active{
    display:block;
}